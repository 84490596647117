import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import {
    InfoLightCircleIcon,
    MegaphoneIcon,
    MicromorgiIcon,
    SurprisesIcon,
} from "../../../../../assets/icons/icons";
import { getAvailableNamespaces } from "../../../../../translations";
import cx from "classnames";
import Tippy from "@tippyjs/react";

const useStyles = createUseStyles({
    planOfSubscription: ({ isHasOnClick }) =>
        Object.assign(
            {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                position: "relative",
                "&.isSelected": {
                    borderRadius: "4px",
                    borderColor: "#6a0dad",
                    borderBottom: "0.5px solid",
                    borderLeft: "0.5px solid",
                    borderRight: "0.5px solid",
                    background: "rgba(134, 75, 210, 0.2)",
                    transition: "all 0.5s",
                },
                "&.disabled": {
                    opacity: "0.5",
                },
            },
            isHasOnClick
                ? {
                      cursor: "pointer",
                      "&:hover": {
                          borderRadius: "4px",
                          border: "solid #6a0dad 0.5px",
                          background: "rgba(134, 75, 210, 0.2)",
                          transition: "all 0.5s",
                      },
                  }
                : {}
        ),
    planOfSubscriptionContent: {
        display: "flex",
        width: "342px",
        alignItems: "center",
    },
    planOfSubscriptionPartOfContent: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "8px",
        textWrap: "pretty",
        fontSize: 14,
        "& div": {
            display: "flex",
            alignItems: "center",
            gap: "8px",
            "& h1": {
                color: "#000000",
                fontWeight: "700",
                letterSpacing: "-1.3px",
                margin: "auto 0",
            },
            "& h2": {
                color: "#00063D",
                textAlign: "center",
                lineHeight: "140%",
                fontWeight: "700",
                margin: 0,
            },
            "& p": {
                color: "#00063D",
                textAlign: "center",
                lineHeight: "140%",
                margin: 0,
                textAlign: "left",
            },
            "& svg": {
                fill: "none !important",
            },
        },

        "& span": {
            fontSize: "0.875rem",
            color: "#00063D",
            textAlign: "center",
            lineHeight: "140%",
            opacity: "0.5",
            padding: "0 8px",
        },
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    end: {
        alignItems: "flex-end",
    },
    micromorgiContainer: {
        justifyContent: "center",
    },
    oldPrice: {
        position: "relative",
        opacity: 0.5,
        fontWeight: "400 !important",
        "&:after": {
            content: "''",
            position: "absolute",
            left: 0,
            right: 0,
            top: "50%",
            height: "1.5px",
            background: "#7d7d70",
            transform: "translateY(-50%)",
        },
    },
    tippy: {
        padding: "4px",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        width: 315,
        wordBreak: "break-word",
        maxHeight: 66,
        borderRadius: 4,
        borderRadius: "14px",
        zIndex: "3",
        overflow: "auto",
    },
    price: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    },
    extraDescription: {
        display: "flex",
        gap: 8,
        alignSelf: "flex-start",
        "& span": {
            maxWidth: "230px",
            display: "flex",
            overflow: "hidden",
            whiteSpace: "nowrap",
            "& p": {
                flexShrink: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
                maxWidth: "100%",
            },
        },
        "& span, h2,p": {
            display: "flex",
            color: "#00063D",
            textAlign: "right",
            fontFamily: "Inter",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "140%",
            margin: 0,
            alignItems: "center",
            gap: 4,

            "& svg": { fill: "transparent !important" },
        },
    },
    oldPromotion: ({ discountContent }) => ({
        position: "relative",
        pointerEvents: "none",
        borderRadius: 4,
        border: "1px solid #CCC",
        background: "#E2E2E2",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        "&:after": {
            position: "absolute",
            left: "50%",
            top: "50%",
            textWrap: "nowrap",
            content: `"${discountContent}"`,
            display: "flex",
            padding: "4px 8px",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            color: "#FFF",
            fontFamily: "Inter",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "capitalize",
            borderRadius: 6,
            background: "linear-gradient(0deg, #FC9F21 0%, #FC9F21 100%)",
            transform: "translateX(-50%) translateY(-50%)",
        },
    }),
    newPromotion: ({ discountContent }) => ({
        position: "relative",
        borderRadius: 4,
        border: "2px solid #FC9F21",
        background: "#FBFBE1",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        "&::after": {
            position: "absolute",
            position: "absolute",
            left: 8,
            top: 0,
            content: `"${discountContent}"`,
            display: "flex",
            padding: "4px 8px",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            color: "#FFF",
            fontFamily: "Inter",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "capitalize",
            borderRadius: 6,
            background: "linear-gradient(0deg, #FC9F21 0%, #FC9F21 100%)",
            transform: "translateX(0) translateY(-50%)",
        },
    }),
});

const PlanOfSubscription = ({
    plan,
    isSelected = false,
    currencySymbol,
    onSelectPlanHandler,
    disabled = false,
    promotion = false,
}) => {
    const discount = plan.discount;
    const hasDiscount = Boolean(discount);
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const classes = useStyles({
        isHasOnClick: Boolean(onSelectPlanHandler),
        discountContent: hasDiscount
            ? !promotion
                ? t(
                      "rookie_profile_page.membership.we_have_a_promotion_for_you"
                  )
                : t("rookie_profile_page.membership.for_you_percentage_off", {
                      PERCENTAGE: +discount.discount_percentage * 100,
                  })
            : undefined,
    });
    const calculateDiscount = (price) =>
        +price *
        (promotion && discount ? 1 - +discount.discount_percentage : 1);
    return (
        <div
            className={`${cx(
                classes.planOfSubscription,
                hasDiscount
                    ? promotion
                        ? classes.newPromotion
                        : classes.oldPromotion
                    : undefined
            )} ${isSelected ? "isSelected" : ""} ${disabled ? "disabled" : ""}`}
            onClick={
                !disabled && onSelectPlanHandler
                    ? onSelectPlanHandler
                    : undefined
            }
        >
            <div className={classes.planOfSubscriptionContent}>
                <div
                    className={cx(
                        classes.planOfSubscriptionPartOfContent,
                        classes.row
                    )}
                >
                    {
                        <div
                            className={cx(
                                classes.micromorgiContainer,
                                hasDiscount ? classes.oldPrice : undefined
                            )}
                        >
                            <MicromorgiIcon height={20} width={20} />
                            <h1>{plan.amount}</h1>
                        </div>
                    }
                    {promotion && (
                        <div className={classes.micromorgiContainer}>
                            <MicromorgiIcon height={20} width={20} />
                            <h1>{calculateDiscount(plan.amount)}</h1>
                        </div>
                    )}
                </div>
                <div
                    className={cx(
                        classes.planOfSubscriptionPartOfContent,
                        classes.column,
                        classes.end
                    )}
                >
                    <div
                        className={cx(
                            classes.price,
                            hasDiscount && !promotion
                                ? classes.oldPrice
                                : undefined
                        )}
                    >
                        {`${currencySymbol}${calculateDiscount(
                            plan.dollar_amount
                        )}`}

                        {t("rookie_profile_page.membership.month")}
                    </div>
                    {promotion && (
                        <div
                            className={cx(
                                classes.price,
                                hasDiscount ? classes.oldPrice : undefined
                            )}
                        >
                            {`${currencySymbol}${plan.dollar_amount}`}
                            {t("rookie_profile_page.membership.month")}
                        </div>
                    )}
                </div>
            </div>
            <div
                className={cx(
                    classes.extraDescription,
                    hasDiscount && !promotion ? classes.oldPrice : undefined
                )}
            >
                <span>
                    <SurprisesIcon height={20} width={21} />
                    <h2>{plan.surprises_count}</h2>
                    <p>{t(`configure_membership_plans_modal.surprises`)}</p>
                </span>
                <span>
                    <MegaphoneIcon height={21} width={20} stroke={"#433C92"} />
                    <p>{plan.group.name}</p>
                </span>
                {!!plan.group?.description && (
                    <Tippy
                        theme="light"
                        placement={"bottom"}
                        trigger="click"
                        inlinePositioning={true}
                        interactive={true}
                        className={classes.tippy}
                        content={plan.group.description}
                    >
                        <span className={classes.tooltipTrigger}>
                            <InfoLightCircleIcon width="16px" height="16px" />
                        </span>
                    </Tippy>
                )}
            </div>
        </div>
    );
};

export default PlanOfSubscription;
